<template>

<!--  根元素，參考 https://book.vue.tw/CH2/2-1-components.html  --> 

<!--  <a></a>改成<router-link>，連結可直接參考router/index.js裡的設定，參考https://v3.router.vuejs.org/zh/api/#to  -->
  <router-link class="search-result-item d-block" :to="{name: 'PathDetail', params: {id:data.serial}}">
    <Icon class="search-result-icon" name="mountains" size="24" />
    <div class="name fs-h5" v-text="data.name"></div>
    <div class="time-consuming fs-body-14">
      <span v-text="(data.totalDistance/1000).toFixed(1)"></span>km / <span v-text="parseInt(data.totalCostMinutes/60)"></span>hr<span v-show="(data.totalCostMinutes%60) > 0"> <span class="d-inline-block pl-1" v-text="(data.totalCostMinutes%60)"></span>min</span>
    </div>
  </router-link>
</template>
      
<script>
    
export default {
  name: 'SearchResultItem',
  data() {
    return {
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    }
  },
  components: {
  },
  computed: {
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
